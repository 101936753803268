.experiences-header
    display: flex
    justify-content: space-between
    padding-bottom: 20px
    border-bottom: 1px solid rgb(247, 247, 255)

.event-date-filter-item
    height: 44px
    width: 100px
    border-radius: 17px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    transition: all 0.2s ease
    filter: none
    background-color: transparent
    .date-icon-wrap
        width: 14px
        height: 14px
        display: flex
        justify-content: center
        align-items: center
        svg
            width: 100%
    &.active
        filter: drop-shadow(rgba(29, 62, 46, 0.12) 0px 6px 12px)
        background-color: rgb(245, 252, 248)
        .filter-text
            color: #0B5731
        .date-icon-wrap
            svg
                path
                    fill: #0B5731
    .filter-text
        color: black

.experience-profile-page
    .experience-item
        &:not(:last-child)
            margin-bottom: 24px

.experience-preview
    .experience-img
        border-radius: 12px
        width: 100%
        height: 100%
        max-height: 233px
        object-fit: cover

.experience-pricing-item
    max-width: 140px

.csv-text
    font-family: 'Inter'
    font-size: 14px
    font-weight: 700
    color: #CCCBF5
    text-decoration: none

.experience-details-page
    position: relative
    .download-csv
        position: absolute
        top: 12px
        right: 0
        background: none
        border: none
        cursor: pointer
        color: #1E1E70
        font-weight: 600
        &:hover
            text-decoration: underline
