.login-page
    height: 100vh
    max-height: 100vh
    overflow: hidden
    .login-container
        height: 100%
        @media (max-width: 1024px)
            display: flex
            justify-content: center

    .login-bg-wrap
        position: relative
        display: flex
        justify-content: center
        @media (max-width: 1024px)
            display: none
    .login-bg
        width: 100%
        height: auto
        object-fit: cover
    .login-overlay
        position: absolute
        top: 0
    .logo-wrap
        margin: 30% 0
        width: 120px
        .logo
            width: 100%
    .form-title
        margin-bottom: 24px
    .login-input
        margin-bottom: 24px
    .form-block
        display: flex
        flex-direction: column
        align-items: center
    .form-container
        width: 100%
        max-width: 350px
    .login-btn
        margin-top: 8px
        color: #fff
