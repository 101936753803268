.transactions
    position: relative
    .download-csv
        position: absolute
        top: 12px
        right: 0
        background: none
        border: none
        cursor: pointer
        color: #1E1E70
        font-weight: 600
        &:hover
            text-decoration: underline

    .selector-wrapper
        display: flex
        flex-direction: row
        gap: 20px
        .selector-currency
            max-width: 100px
.page-content
    .transaction-select-filter
        display: flex
        justify-content: flex-start
        flex-direction: row
        gap: 30px
