.home-page
    position: relative
    .percentage-item
        position: absolute
        top: 4px
        z-index: 10
    .percentage-diff
        display: flex
        align-items: center
        flex-direction: row
        .percentage-icon-wrap
            width: 25px
            height: 25px
            svg
                width: 100%
                height: auto
        &.up
            .percentage-title
                color: #34b119
            .percentage-icon-wrap
                svg
                    path
                        fill: #34b119
        &.down
            .percentage-title
                color: #f15962
            .percentage-icon-wrap
                svg
                    transform: rotate(73deg)
                    path
                        fill: #f15962
        .percentage-title
            margin-left: 8px
.heat-map-wrapper

    display: flex
    flex-direction: row
    justify-content: space-between
    gap: 20px
    .heat-map-cities
        width: 30%
    .heat-map-members
        width: 70%
    .cities-item
        color: #747481
        cursor: pointer
        &.active,
        &:hover
            color: #1E1E70
    .heat-map-info
        margin-top: 10px
    .heat-map-cities--members
        max-height: 500px
        overflow: auto

.heat-map-page
    .download-csv
        margin-top: 30px
        background: none
        border: none
        cursor: pointer
        color: #1E1E70
        font-weight: 600
        display: inline
        margin-bottom: 10px
        text-align: right
        &:hover
            text-decoration: underline
