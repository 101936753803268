$info-text-color: rgb(247, 248, 250)
$title-text-color: rgb(36, 41, 46)
$wrapper-border-color: rgba(36, 41, 46, 0.12)
$analytic-wrapper-height: 166px

.analytics-report-wrapper
  border: 1px solid $wrapper-border-color
  max-height: calc($analytic-wrapper-height + 40px)
  width: 100%
  &__header
    padding: 8px
    display: flex
    align-items: center
    justify-content: space-between

.analytics-report-view
  width: 50%
  height: $analytic-wrapper-height
  padding: 12px
  border-radius: 2px
  display: flex
  align-items: center
  &:first-child
    margin-right: 3px
  &__content
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

  &__content_header
    color: $info-text-color
    font-size: 20.75px
    line-height: 30px

  &__content_counter
    color: $info-text-color
    font-size: 90px
    line-height: 90px

@media (max-width: 880px)
  .analytics-report-view__content_header
    font-size: 16px
    line-height: 24px

  .analytics-report-view__content_counter
    font-size: 60px
    line-height: 60px

@media (max-width: 680px)
  .analytics-report-view__content_header
    font-size: 14px
    line-height: 18px

  .analytics-report-view__content_counter
    font-size: 40px
    line-height: 40px
