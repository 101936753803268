.room-cell
  max-width: 180px
  width: 100%
  height: 36px
  border-radius: 8px
  display: flex
  justify-content: center
  align-items: center
.room-cell-name
  max-width: 90%
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  font-size: 14px
  color: #fff

.room-cell-empty
  background-color: #dfdcf0

.room-cell-reserved
  background-color: #a097ca
