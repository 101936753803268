.rdrDefinedRangesWrapper
  display: none

[role='tooltip'].date-pop-up-content
  width: fit-content

.table-cell-hover
  transition: all .3s ease-in
  &:hover
    text-decoration: underline
    cursor: pointer
.table-cell-transaction-status
  &.succeeded
    span
      color: #006908
      background-color: #a6eb84
  &.failed
    span
      color: #b3093c
      background-color: #ffccdf
  &.canceled
    span
      color: #545969
      background-color: #d5dbe1
  &.refunded
    span
      color: #545969
      background-color: #d5dbe1
  span
    padding: 4px 8px
    height: 20px
    border-radius: 4px
    text-transform: capitalize
.event-date-filter-item
  height: 44px
  width: 100px
  border-radius: 17px
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  transition: all 0.2s ease
  filter: none
  background-color: transparent
  .date-icon-wrap
    width: 14px
    height: 14px
    display: flex
    justify-content: center
    align-items: center
    svg
      width: 100%
    &.active
      filter: drop-shadow(rgba(29, 62, 46, 0.12) 0px 6px 12px)
      background-color: rgb(245, 252, 248)
      .filter-text
        color: #0B5731
        .date-icon-wrap
          svg
            path
              fill: #0B5731
    .filter-text
      color: black
