.membership-header
    display: flex
    justify-content: space-between
    padding-bottom: 20px
    border-bottom: 1px solid rgb(247, 247, 255)

.table-head.MuiTableRow-head
    th
        &.MuiTableCell-head
            border-bottom: 1px solid rgb(247, 247, 255)

.membership-filters
    .filters-item
        margin-left: 24px
        width: 220px
    .cm-filter
        width: 320px !important

.member-profile-status
    width: 100%
    max-width: 150px
    margin-top: 24px

.member-page
    .promo-item
        margin-right: 14px
    .profile-promo-btn
        width: 40px
        height: 40px
        border-radius: 100%
        border: 1px solid rgb(0, 0, 0)
    .member-table-status
        width: 100%
        max-width: 130px
        padding: 7px

.referral-badge
    margin-left: 5px
    background: rgb(252, 245, 244)
    border-radius: 100%
    padding: 4px 11px
    font-size: 14px
    line-height: 21px
    text-align: center
    &.pending
        color: rgb(237, 28, 36)
    &.success
        color: rgb(0, 127, 67)

.member-profile-avatar
    display: flex
    align-items: center
    justify-content: center

.text-break
    word-break: break-all
