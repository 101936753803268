.awaiting-review-members-page
    .container
        display: flex
        flex-direction: column
        align-items: center
    .formik-container
        width: 30%
        @media (max-width: 1024px)
            width: 100%

    .item-container
        width: 100%
        border: solid 0.25px black
        border-radius: 12px
        padding: 24px
        margin-bottom: 12px
        display: flex
        flex-direction: column
    .member-name-text
        color: black
    .button-disabled
        background-color: rgba(120, 195, 106, 0.1),
        color: #78C36A,
