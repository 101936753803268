.page
    .page-header
        position: relative
        background-color: rgb(251, 251, 255)
        margin-bottom: 24px
        display: flex
        justify-content: flex-start
    .page-back-btn
        cursor: pointer
        display: flex
        .icon
            margin-right: 4px
        .back-text
            font-weight: 600
            font-size: 18px
            line-height: 24px
            color: #4A4A50