@import './components/index.sass'
@import './pages/index.sass'

#root
  min-height: 100vh
  background-color: #FBFBFF

.bg-w
  background-color: #FFFFFF

[role='tooltip'].popup-content
  width: auto
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 2px

input[type="number"]::-webkit-inner-spin-button
  display: none

.round-btn
  width: 32px
  height: 32px
  border: 1px solid #CCCBF5

.error-border
  border: 1px solid rgba(237, 28, 35, 0.655) !important
  border-radius: 12px