.sidebar
    position: relative
    .sidebar-links-wrap
        padding: 0 14px
        margin-bottom: 40px
    .sidebar-link
        padding: 14px 12px
        &.active
            img
                -webkit-filter: brightness(10)
                filter: brightness(10)
    .sidebar-link-icon
        margin-right: 16px
    .sidebar-sublinks
        padding-left: 32px
    .sidebar-logo-wrap
        padding: 32px 24px
        width: 110px
        margin-bottom: 32px
        .sidebar-logo
            width: 100%
    .sidebar-sublink-text
        color: #747481
        &::after
            content: ''
            display: none
            width: 3px
            height: 100%
            background-color: #1E1E70
            position: absolute
            top: 0
            right: 0
        &.active
            color: #1E1E70
            &::after
                display: block
    .sidebar-logout
        display: flex
        width: 100%
        z-index: 2
        cursor: pointer
        position: sticky
        left: 0
        bottom: 0
        padding: 16px 24px 16px
        z-index: 2
        background-color: #fff
        border-top: 1px solid rgba(0, 0, 0, 0.05)
        img
            margin-right: 18px
    .sidebar-nav-wrap
        display: flex
        flex-direction: column
        justify-content: space-between
        flex: 1
        position: relative